/**********************************************************
****************************TOOLS**************************
***********************************************************/

*{
	box-sizing: border-box; 
}

.root {
  --main-police:  "Open Sans", arial, sans-serif;
  --main-color-text: #262626;
  --color-orange: #ef6d0b;
}

html{
	font-size: 62.5%; 
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/**********************************************************
***********************GENERALITES**************************
***********************************************************/
.App {
  text-align: center;
  padding: 1rem;
  font-family: var(--main-police);
  color: var(--main-color-text);
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  min-height:100vh; 
	display:flex; 
	flex-direction:column;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

main button:focus {outline:0;}

main button {
  border: 1px solid #ef6d0b;
  background-color: #ef6d0b;
  padding: 15px;
  margin: 25px;
  border-radius: 10px;
}

.orange_button{
  color: #ffffff;
}

/****Header*****/
.header-nav {
  padding: 30px;
  position: sticky; top: 0;
  background-image:linear-gradient(90deg, rgba(239,109,11,1) 60%, rgba(255,255,255,1) 100%);
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.header-nav a {
  text-decoration: none;
  color: white;
  padding: 0 25px
}

/**********************************************************
****************************Form***************************
***********************************************************/
.c-form {
  width:60%;
  text-align: center;
  margin: auto;
  padding: 25px;
  border: 3px solid #38d8d8;
  border-radius: 15px;
}

.c-form input {
  display: block;
  width:90%;
  padding: 10px;
  text-align: center;
  margin: 15px auto
}

.changePhoto{
  color:#ffffff;
}

textarea{
  width: 90%;
  height: 90px;
}

.c-form  .saveProfil{
  width:60%;
}

.error {
  color: red;
}


.calendar-admin li {
  list-style: none;
  border: 2px solid #23a339;
  width: 40%;
  margin: 15px auto;
  padding: 25px
}

li.not-move {
  border: 2px solid #a9fcaa;
}
li.not-move p {
  color: grey;
}

.modal-lesson {
  width: 40%;
  position: absolute;
  top: 15%;
  left: 30%;
  background-color: white;
  border: 2px solid black;
}

.delete {
  color: red;
  background-color: #ffffff;
  border: none;
  font-weight: bold;
}

main button.redButton {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  color: #000000;
  margin-left: 85%;
}

main a {
  color: #23a339;
  text-decoration: none;
  padding: 15px;
  margin: 25px;
  font-size: 13px;
  font-weight: bold;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container_img{
  width: 150px; /* Vous pouvez ajuster cette valeur selon vos besoins */
  height: 150px; /* Vous pouvez ajuster cette valeur selon vos besoins */
  border-radius: 50%; /* Pour une image de profil circulaire */
  overflow: hidden; /* Pour s'assurer que l'image ne dépasse pas du conteneur */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #ccc; /* Optionnel : ajoute une bordure autour de l'image */
  margin: auto;
}

.profilImg{
  width: 100%;
  height: 100%;
  object-fit: cover; /* Assure que l'image couvre tout le conteneur tout en conservant ses proportions */
}

.img_background img{
  width: 100%;
  height: auto;
}

.dashboard{
  padding: 5px;
  background-color:#f5f5f5;
}
